/*eslint-disable */
var $ = window.jQuery = require('jquery');
var interval;

import {bus} from '@/main';
import chatWithPatient from "@/components/private/chat-of-patient/index.vue";
import countries from '@/services/country.json'
import {
  mapGetters
} from 'vuex';
import moment from 'moment';
import prescriptionOfDoctor from "./pre-fo-doctor/index.vue";
import remarksOfDoctor from "./remarks/index.vue";

export default {
  name: 'detail',
  components: {
    'chat-with-patient' : chatWithPatient,
    'remark-of-doctor' : remarksOfDoctor,
    'prescription-of-doctor' : prescriptionOfDoctor
  },
  props: [],
  data () {
    return {
      appointmentDetailState:null,
      countryList:[],
      appintmentDetailsTab:'chatWithDoctor',
      chatWithDoctorInfo:null,
      consultWorkspaceTabs:'prescriptions',
      selfUserId:'',
      counterText:''
    }
  },
  computed: {
    ...mapGetters([
      'appointmentDetailGetetrs'
    ]),
    getAppointmentDetailsFun(){
      return this.appointmentDetailState = this.$store.getters.appointmentDetailGetetrs.data
    },
    setInitialState(){
      if(this.appointmentDetailState){
        this.chatWithDoctorInfo = this.appointmentDetailState
      }
      return
    }
  },
  mounted () {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
    bus.$on('userUpdateStatus', (data) => {
    });
    this.getAppointmentDetailsFun
    this.setInitialState
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    // this.countdownTimer()
  },
  methods: {
    startCountdownTimer(joinStartTime){
      var currentLocalDate = moment().format('MMMM Do YYYY');
      var startDateCheck = moment(joinStartTime).format('MMMM Do YYYY')
      var currentLocalHours = moment().format('HH')
      var currentLocalTime = moment().format('mm:ss')
      var currentLocalSec = moment().format('ss')
      var startTimeHours = moment(joinStartTime).format('HH')
      var startTimeCheck = moment(joinStartTime).format('mm:ss')
      var startTimeSec = moment(joinStartTime).format('mm:ss')
      if(startDateCheck >= currentLocalDate){
          var totalMinutesOfStartMeeting  = startTimeHours*parseInt(60) + parseInt(startTimeCheck) + parseInt(15);
          var totalMinutesOfCurrent  = currentLocalHours*parseInt(60) + parseInt(currentLocalTime);
          if(totalMinutesOfStartMeeting > totalMinutesOfCurrent){
            var validTime = totalMinutesOfStartMeeting - totalMinutesOfCurrent
            if(parseInt(15) > validTime){
              this.counterText = validTime + ':' + '00';
              this.countdownTimer()
              return this.counterText;
            }else{
              return false;
            }
          }else{
            return false;
          }
      }else{
        return false;
      }
    },
    countdownTimer() {
      var __self = this
      clearInterval(interval);
      interval = setInterval( function() {
          var timer = $('.js-timeout').html();
          timer = timer.split(':');
          var minutes = timer[0];
          var seconds = timer[1];
          seconds -= 1;
          if (minutes < 0) return;
          else if (seconds < 0 && minutes != 0) {
              minutes -= 1;
              seconds = 59;
          }
          else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds;
          $('.js-timeout').html(minutes + ':' + seconds);
          if (minutes == 0 && seconds == 0){
            window.location.reload()
            clearInterval(interval)
          };
      }, 1000);
    },
    getCountryNameFun(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
    appointmentTabFun(data){
      this.appintmentDetailsTab = data
      if(data != 'chatWithDoctor'){
        bus.$emit('socketClosedNotifications', true);
      }
    },
    consultWorkspaceTabsActvmethod(status){
      this.consultWorkspaceTabs = status
    }
  },
  watch:{
    getAppointmentDetailsFun(){
      return this.$store.getters.appointmentDetailGetetrs
    },
    setInitialState(){
      if(this.appointmentDetailState){
        this.chatWithDoctorInfo = this.appointmentDetailState.doctor_detail
      }
      return
    }
  },
  beforeRouteLeave: function(to, from, next) {
    bus.$emit('socketClosedNotifications', true);
    next()
  }
}