/*eslint-disable */

import {bus} from '@/main';

export default {
  name: 'remarks',
  components: {},
  props: ['doctorId'],
  data () {
    return {
      remarkListData:null,
      remarkListResults:[],
      remarkListPageTab:true,
      remarkInfoData:null
    }
  },
  computed: {

  },
  mounted () {
    bus.$on('patientRemarkBus', (data) => {
      this.getRemarkListFun();
    })
    this.getRemarkListFun();
  },
  methods: {
    getRemarkListFun(){
      this.$store.dispatch('getRemarksListApiFun', {id:this.doctorId}).then((res)=>{
        this.remarkListData = res.data
        this.remarkListResults = res.data.data
      })
    },
    remarkInfoFun(data){
      this.remarkInfoData = data
      this.remarkListPageTab = false
    },
    backRemarkInfoFun(){
      this.remarkInfoData = null
      this.remarkListPageTab = true
    }
  }
}


