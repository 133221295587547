import { render, staticRenderFns } from "./chat-of-patient.html?vue&type=template&id=33ff7649&scoped=true&external"
import script from "./chat-of-patient.js?vue&type=script&lang=js&external"
export * from "./chat-of-patient.js?vue&type=script&lang=js&external"
import style0 from "./chat-of-patient.scss?vue&type=style&index=0&id=33ff7649&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ff7649",
  null
  
)

export default component.exports