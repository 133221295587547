import { render, staticRenderFns } from "./remarks.html?vue&type=template&id=6c2b82f2&scoped=true&external"
import script from "./remarks.js?vue&type=script&lang=js&external"
export * from "./remarks.js?vue&type=script&lang=js&external"
import style0 from "./remarks.scss?vue&type=style&index=0&id=6c2b82f2&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2b82f2",
  null
  
)

export default component.exports